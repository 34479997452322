import React, {useEffect, useState} from "react"

import "./ManagePageList.scss"

import defaultAvatar from "images/fb-logo-grey.png"
import {useDispatch, useSelector} from "react-redux";

import Button from "../Button";
import {removePageFromUser, updatePage} from "../../../actions/requests";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";
import ModalWindow from "../ModalWindow";

const ManagePageList = ({ pageDetails }) => {
    const dispatch = useDispatch()
    const {attributes: page, id: id} = pageDetails
    const has_errors = page.app_errors.length > 0
    const [showDeletePageModal, setShowDeletePageModal] = useState(false)

    const hidePageFromDashboard = (e) => {
        const formData = new FormData()
        formData.append("page[hide_from_dashboard]", !page.hide_from_dashboard)
        dispatch(updatePage(id, formData, true))
    }

    const closeDeletePageModal = (e) => {
        setShowDeletePageModal(false)
    }

    const deletePageHandler = (e) => {
        setShowDeletePageModal(true)
    }

    const handleDeletePage = (e) => {
        e.stopPropagation()
        dispatch(removePageFromUser(id))
        setShowDeletePageModal(false)
    }

    useEffect(()=>{
        ReactTooltip.rebuild()
    },[pageDetails])

  return (
      <div className="nav-link ms-3 my-3">
            <div className="row">
              <div className="col-lg-7 d-flex">
                <div>
                    <img className="rounded-circle" alt="page image" src={page.picture_url ? page.picture_url : defaultAvatar}/>

                </div>

                  <div className={`d-inline page-name align-self-center mx-2`} >
                  {page.displayName}

                  {has_errors && <div className='badge badge-red mx-2' data-tip={page.app_errors}>
                      <a href='https://commentguard.io/how-to-refresh-your-access-token' target='_blank'>Refresh your access token</a>
                  </div>}

                  {page.has_clone && <div className='badge badge-orange mx-2' data-tip="This page is already added to another account. This may cause conflicts when configuring moderation and automation settings.">
                      Duplicated
                  </div>}

                </div>
              </div>
              <div className="col-lg-3 d-flex pl-0">
                  <div className="message-xs remove-page-wrapper align-self-center">
                      <Button className="remove-page-link" clickHandler={(e) => {
                          e.stopPropagation()
                          dispatch(hidePageFromDashboard)}
                      }>
                          {page.hide_from_dashboard ?
                                <>
                                    <FontAwesomeIcon icon={faEyeSlash} className="me-2" />
                                    Show in dashboard
                                </> :
                                <>
                                    <FontAwesomeIcon icon={faEye} className="me-2" />
                                    Hide from dashboard
                                </>}

                      </Button>
                  </div>
              </div>
              <div className="col-lg-2 d-flex pl-0">
                   <div className="message-xs remove-page-wrapper align-self-center">
                       <Button className="remove-page-link" clickHandler={(e) => deletePageHandler(e)}>
                           Remove this page?
                       </Button>
                   </div>
              </div>
            </div>

          {showDeletePageModal && <ModalWindow hideClose={true}
                                               header="Are you sure?"
                                               closeHandler={() => closeDeletePageModal()}>
              <div className="mb-4">
                  Removing a page will remove all its comments and messages from our database. These can't be recovered when the page is re-added at a later time.
              </div>
              <div className="row">
                  <form onSubmit={handleDeletePage}>
                      <button type="submit" className="btn btn-danger float-end">Confirm</button>
                      <button type="reset" className="btn btn-secondary float-end mx-2"
                              onClick={() => closeDeletePageModal()}>Cancel</button>
                  </form>
              </div>
          </ModalWindow>
          }
      </div>
  )
}

export default React.memo(ManagePageList)
