import React, {useEffect, useState, useRef} from 'react'
import {changeFilter} from "../../../actions/pages";
import {exportComments, requestPageComments} from "../../../actions/requests";
import {batch, useDispatch, useSelector} from "react-redux";
import {filtersIsChanged} from "../../../utils/app";
import FilterItems from "../FilterItems";
import {filterList} from "../../../constants";
import Button from "../Button";
import FilterIcon from "images/filter.svg"
import FilterSlashIcon from "images/filter-slash.svg"
import OrderByTime from "../FilterItems/OrderByDate";
import {setShowSearch} from "../../../actions/ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {faFileCsv} from "@awesome.me/kit-a13c95776b/icons/modules/sharp/light";
import ModalWindow from "../ModalWindow";
import {faFileExport} from "@awesome.me/kit-a13c95776b/icons/modules/sharp/regular";

const Filters = () => {
  const dispatch = useDispatch()
  const exportForm = useRef()
  const directType = useRef()
  const {list: filters} = useSelector(state => state.filters)
  const [showExportModal, setShowExportModal] = useState(false)
    const [load, setLoad] = useState(false)

  const [matches, setMatches] = useState(
      window.matchMedia("(min-width: 992px)").matches
  )

  useEffect(() => {

    const media = window.matchMedia("(min-width: 992px)")

    try {
      // Chrome & Firefox
      media.addEventListener('change', e => setMatches( e.matches ));
    } catch (e1) {
      try {
        // Safari
        media.addListener('change', e => setMatches( e.matches ));
      } catch (e2) {
        console.error(e2);
      }
    }
  }, []);

  const handleFilter = (e) => {
    const value = e.currentTarget.value
    const filterName = e.currentTarget.name
    dispatch(changeFilter(filterName, value))
    dispatch(requestPageComments())
  }


  const resetFilter = (e) => {
    const page_id = filters.by_page_id
    e.preventDefault()
    batch(() => {
      dispatch(changeFilter())
      page_id && dispatch(changeFilter("by_page_id", page_id))
      dispatch(requestPageComments())
    })
  }

    const handleExport = (e) => {
        e.preventDefault()
        directType.current.checked && setLoad(true)
        dispatch(exportComments(exportForm.current, setLoad, setShowExportModal, directType.current.checked))
        !directType.current.checked && setShowExportModal(false)
    }

  return (
      <>
        <div className="d-flex justify-content-start align-items-end">
          <div className="btn-group filter-m-group">
              <a href="#" className="filter-link dropdown-toggle d-flex align-items-center justify-content-start"
                 data-bs-toggle="dropdown"
                 data-bs-auto-close="outside"
                 aria-expanded="false">
                <span className="d-none d-xxs-block">Filters</span>
                <img src={FilterIcon} className="ms-2 my-2" width="18" height="16"/>
              </a>
              <ul className="dropdown-menu dr-main">
                <li>
                  <FilterItems matches={matches} handleFilter={handleFilter}/>
                </li>
              </ul>
            </div>

          { filtersIsChanged(filters) && <div className="d-flex">
            <a href="#" onClick={resetFilter}>
              <img src={FilterSlashIcon} className="ms-2" width="20" height="22"/>
            </a>
          </div>}

          <OrderByTime handleFilter={handleFilter}/>

          <div className="btn-group filter-group show-search-gr">
                <span className="mx-2 show-search" data-tip="Search" onClick={()=>dispatch(setShowSearch())}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="fa-fw"/>
                </span>
          </div>

          <div className="btn-group filter-group show-search-gr">
                <span className="mx-1 show-search" data-tip="Export" onClick={()=>setShowExportModal(true)}>
                    <FontAwesomeIcon icon={faFileExport} className="fa-fw"/>
                </span>
          </div>

        </div>

          {showExportModal &&
          <ModalWindow hideClose={true}
                       header="Export comments"
                       closeHandler={() => setShowExportModal(false)}>
              <div className="mb-4">
                  All comments within your current tab and/or filter will be exported.
              </div>
              {load ?
                  <div className="mb-4">
                      Generating export...
                  </div>
              : <form onSubmit={handleExport} ref={exportForm}>
                  <div className="mb-4">
                      <div className="form-check mb-3">
                          <input className="form-check-input" type="radio" ref={directType} name="export_type"
                                 id="flexRadioDefault1" value="direct" defaultChecked={true}/>
                              <label className="form-check-label" htmlFor="flexRadioDefault1">
                                  Direct download
                              </label>
                              <div className="mt-1 fs-08">Download the export directly to your device.</div>
                      </div>
                      <div className="form-check my-2">
                          <input className="form-check-input" type="radio" name="export_type" id="flexRadioDefault2"
                                  value="email"/>
                              <label className="form-check-label" htmlFor="flexRadioDefault2">
                                  Via email
                              </label>
                              <div className="mt-1 fs-08">Receive a link to download the export via email.</div>
                      </div>
                  </div>
                  <div>
                          <button type="submit" className="btn btn-primary float-end">Export</button>
                          <button type="reset" className="btn btn-tr float-end mx-2"
                                  onClick={() => setShowExportModal(false)}>Cancel</button>
                  </div>
              </form>}
          </ModalWindow>
          }
    </>
  )
}

export default React.memo(Filters)